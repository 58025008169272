import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Lightbox from 'bs5-lightbox';

// Lightbox options
const options = {
	keyboard: true,
	size: 'fullscreen',
};

function Work() {

	useEffect(() => {
		const handleLightboxClick = (e) => {
			e.preventDefault();
			const lightbox = new Lightbox(e.currentTarget, options);
			lightbox.show();
		};
		const lightboxElements = document.querySelectorAll('.my-lightbox-toggle');
		lightboxElements.forEach((el) => {
			el.addEventListener('click', handleLightboxClick);
		});
		return () => {
			lightboxElements.forEach((el) => {
				el.removeEventListener('click', handleLightboxClick);
			});
		};
	}, []);

	return (
		<>
			<div className="work-section">
				<div className="container">
					<div className="heading-block mb-5 mt-2">
						<h1 className="font-44 color-black text-uppercase text-center">OUR Work</h1>
					</div>
				</div>

				<div className="container-fluid">
					<div className="main-gallery">
						<div className="masonry">
							<a href="img/1.jpg" className="brick my-lightbox-toggle">
								<img src="img/1.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/2.jpg" className="brick my-lightbox-toggle">
								<img src="img/2.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/5.jpg" className="brick my-lightbox-toggle">
								<img src="img/5.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/3.jpg" className="brick my-lightbox-toggle">
								<img src="img/3.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/16.jpg" className="brick my-lightbox-toggle">
								<img src="img/16.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/6.jpg" className="brick my-lightbox-toggle">
								<img src="img/6.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/5.jpg" className="brick my-lightbox-toggle">
								<img src="img/5.jpg" alt="Bayaart" title="Bayaart" />
							</a>
							<a href="img/4.jpg" className="brick my-lightbox-toggle">
								<img src="img/4.jpg" alt="Bayaart" title="Bayaart" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Work;
