import React, { useState } from 'react';
import { BsInstagram, BsVimeo, BsYoutube, BsLinkedin, BsBehance, BsList } from 'react-icons/bs';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            {/* <div className="top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-3">
                        </div>
                        <div className="col-md-6 col-9">
                            <div className="d-flex justify-content-end">
                                <ul className="social-media d-flex">
                                    <li><a href="#"><BsInstagram /></a></li>
                                    <li><a href="#"><BsVimeo /></a></li>
                                    <li><a href="#"><BsYoutube /></a></li>
                                    <li><a href="#"><BsLinkedin /></a></li>
                                    <li><a href="#"><BsTwitter /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <header id="header" className="sticky-top">
                <div className="container d-flex align-items-center">
                    <a href="/" className="logo me-auto">
                        <img src="img/logo.gif" className="img-fluid" />
                        <span>BAYAART</span>
                    </a>
                    <nav id="navbar" className={`navbar ${isMenuOpen ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><a className="nav-link scrollto" href="/">Home</a></li>
                            {/* <li><a className="nav-link scrollto" href="#">About</a></li> */}
                            <li>
                                <a className="nav-link scrollto" href="/work">Works</a>
                            </li>
                            <li>
                                <a className="nav-link scrollto" href="/still">Service</a> 
                            </li>
                            {/* <li><a className="nav-link scrollto" href="/blog">Blog</a></li>
                            <li><a className="nav-link scrollto" href="/career">Career</a></li> */}
                            <li><a className="nav-link scrollto" href="mailto:info@bayaart.in">Contact</a></li>
                            <div className="d-flex side-navbar">
                                <ul className="social-media d-flex">
                                    <li><a href="https://www.instagram.com/bayaartstudio/" target="_blank"><BsInstagram /></a></li>
                                    {/* <li><a href="#"><BsVimeo /></a></li> */}
                                    <li><a href="https://www.youtube.com/@Bayaart."target="_blank"><BsYoutube /></a></li>
                                    {/* <li><a href="#"><BsLinkedin /></a></li> */}
                                    <li><a href="https://www.behance.net/BayaArt1?tracking_source=search_projects|bayaart" target="_blank"><BsBehance /></a></li>
                                </ul>
                            </div>
                        </ul>

                        <span className="mobile-nav-toggle" onClick={toggleMenu}><BsList /></span>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default Header;
