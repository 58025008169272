import React from 'react';
import { BsInstagram, BsVimeo, BsYoutube, BsLinkedin, BsTwitter, BsChevronRight } from 'react-icons/bs';

function Footer() {
    return (
        <footer >
            <div className="rt-footer">
                <div className="container">
                    <div className="text-center">
                        <h1 className="color-white text-uppercase">LET’S COLLABORATE.</h1>
                        <a href="mailto:info@bayaart.in" className="color-white ask-price">Ask Plans & Pricing <span><BsChevronRight/></span></a>
                       
                    </div>
                </div>  
            </div>
            <div className="copyright">
                    <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <div className="footer-logo">
                                        <div className="ft-logo pt-3">
                                            <img src="img/Footer-logo.svg"/>
                                        </div>
                                    </div>
                                    <p className="tag-name pt-1">H-221,4th floor,office <br></br>number-407, sector-63, Noida, 201301<br></br>
                                    <a href="mailto:info@bayaart.in" className="a-tag">info@bayaart.in</a>
                                    </p>

                                </div>
                                <div className="col-md-6 col-6">
                                    <div className="fotter-block">
                                        <ul className="social-box mb-0 pb-0">
                                            <li><a href="#">Terms</a></li>
                                            <li><a href="#">Privacy Policy</a></li>
                                        </ul>
                                        <p className="pt-0">© 2024 All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            
        </footer>
    )
}

export default Footer;