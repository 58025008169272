import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Lightbox from 'bs5-lightbox';
import Carousel from 'react-bootstrap/Carousel';

// Lightbox options
const options = {
    keyboard: true,
    size: 'fullscreen',
};
function Home() {

    useEffect(() => {
        const handleLightboxClick = (e) => {
            e.preventDefault();
            const lightbox = new Lightbox(e.currentTarget, options);
            lightbox.show();
        };
        const lightboxElements = document.querySelectorAll('.my-lightbox-toggle');
        lightboxElements.forEach((el) => {
            el.addEventListener('click', handleLightboxClick);
        });
        return () => {
            lightboxElements.forEach((el) => {
                el.removeEventListener('click', handleLightboxClick);
            });
        };
    }, []);

    const videoSources = [
        'img/video/slider-video.mp4',
        // 'img/video/slide3.mp4',
        // 'img/video/slide4.mp4',
        // 'img/video/slide5.mp4',
        // 'img/video/slide6.mp4',
        // 'img/video/slide7.mp4',
        // 'img/video/slide8.mp4',
    ];

    const carouselRef = useRef(null);
    const videoRefs = useRef([]);


    const handleSlideEnd = (event) => {
        const activeIndex = event.to;
        const activeVideo = videoRefs.current[activeIndex];
        if (activeVideo) {
            activeVideo.currentTime = 0;
            activeVideo.play();
        }
    };

    return (
        <>
            <div className="fu-sl-w">
                <Carousel
                    ref={carouselRef}
                    fade
                    controls={false}
                    indicators={false}
                    interval={null}
                    onSlid={handleSlideEnd}
                >
                    {videoSources.map((src, index) => (
                        <Carousel.Item key={index}>
                            <video
                                className="img-fluid"
                                autoPlay
                                muted
                                loop
                                ref={el => (videoRefs.current[index] = el)}
                                onEnded={() => {
                                    if (carouselRef.current) {
                                        carouselRef.current.next();
                                    }
                                }}
                            >
                                <source src={src} type="video/mp4" />
                            </video>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className="Section gray-bg">
                <div className="container">
                    <div className="heading-block mb-5 text-center art-content">
                        <h1 className="color-black text-uppercase text-center">Bayaart</h1>
                        <p className="tag-name">is a team of 3D Generalist is the broad experiance in the cinematic visual , Post production and rendering fields.
                            <br></br>« Light, Shadow ,Texturing and Color are tool that we combine to communicate the values of architecture through an image» </p>
                    </div>
                </div>
            </div>
            <div className="Section banner-counter py-0">
                <video className="img-fluid" autoPlay loop muted>
                    <source src="img/video/ab-out.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="bg-black section padding-50 mar-nag">
                <div className="container">
                    <div className="row thum-grid">
                        <div className="col-md-6">
                            <a href="img/story-1.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-1.jpg" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href="img/story-2.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-2.jpg" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href="img/story-3.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-3.jpg" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href="img/story-4.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-4.jpg" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href="img/story-5.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-5.jpg" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href="img/story-6.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-6.jpg" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href="img/story-7.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-7.jpg" className="img-fluid" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <a href="img/story-8.jpg" className="thumb-img my-lightbox-toggle">
                                <img src="img/story-8.jpg" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Section banner-section">
                <div className="container">
                    <div className="centered-content text-center">
                        <h1 className="font-44 color-white">OUR PRODUCTS INCLUDE：</h1>
                        <h2 className="font-35 color-white">STILL / MOVIE / 360 SPACE TOURE / A REAL VR / PHYSICAL MODEL</h2>
                    </div>
                </div>
            </div>
            <div className="Section gray-bg padding-50">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="img-card">
                                <img src="img/work-1.jpg" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="content-block">
                                <h1 className="color-black text-uppercase mb-3">What we do</h1>
                                <p className="mb-3">Our job is all about crafting images with the utmost care
                                    and attention to detail. As architects and image makers, we constantly
                                    strive for improvement to create the best possible outcome. We understand
                                    that crucial elements like texturing, lighting, and selecting the perfect
                                    perspective are key in conveying the story we want to tell. It’s a constant
                                    learning process, but we are always up for the challenge of refining our
                                    craft.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bg-img-ful"></div>
        </>
    );
}

export default Home;
