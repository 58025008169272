import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Lightbox from 'bs5-lightbox';

// Lightbox options
const options = {
	keyboard: true,
	size: 'fullscreen',
};
function Still() {

	useEffect(() => {
		const handleLightboxClick = (e) => {
			e.preventDefault();
			const lightbox = new Lightbox(e.currentTarget, options);
			lightbox.show();
		};
		const lightboxElements = document.querySelectorAll('.my-lightbox-toggle');
		lightboxElements.forEach((el) => {
			el.addEventListener('click', handleLightboxClick);
		});
		return () => {
			lightboxElements.forEach((el) => {
				el.removeEventListener('click', handleLightboxClick);
			});
		};
	}, []);

	return (
		<>
			<div className="still-section">
				<div className="container">
					<ul className="service-link">
						<li><a href="/still" className="active">Still</a></li>
						{/* <li><a href="#">Movie</a></li> */}
					</ul>
				</div>
				<div className="bradcrumb-img mb-5">
					<div className="overlay-bg">
					<div className="container">
						<div className="heading-block mb-5 mt-2 overlay-text">
							<h1 className="font-44 color-white text-uppercase text-center">STILL</h1>
							<p className="color-white font-20">
							Our job is all about crafting images with the utmost care and attention to detail. As architects and image makers, we constantly strive for improvement to create the best possible outcome.
							</p>
						</div>
					</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="main-gallery service-gallary">
						<div className="masonry">
							<a href="img/1.jpg" className="brick my-lightbox-toggle">
								<img src="img/1.jpg" alt="Bayaart" title="Bayaart" />
								<p className="text-ser">DUNNAN FOREST｜MVRDV</p>
							</a>
							<a href="img/2.jpg" className="brick my-lightbox-toggle">
								<img src="img/2.jpg" alt="Bayaart" title="Bayaart" />
								<p className="text-ser">DUNNAN FOREST｜MVRDV</p>
							</a>
							<a href="img/5.jpg" className="brick my-lightbox-toggle">
								<img src="img/5.jpg" alt="Bayaart" title="Bayaart" />
								<p className="text-ser">DUNNAN FOREST｜MVRDV</p>
							</a>
							<a href="img/3.jpg" className="brick my-lightbox-toggle">
								<img src="img/3.jpg" alt="Bayaart" title="Bayaart" />
								<p className="text-ser">DUNNAN FOREST｜MVRDV</p>
							</a>
							<a href="img/16.jpg" className="brick my-lightbox-toggle">
								<img src="img/16.jpg" alt="Bayaart" title="Bayaart" />
								<p className="text-ser">DUNNAN FOREST｜MVRDV</p>
							</a>
							<a href="img/6.jpg" className="brick my-lightbox-toggle">
								<img src="img/6.jpg" alt="Bayaart" title="Bayaart" />
								<p className="text-ser">DUNNAN FOREST｜MVRDV</p>
							</a>
						</div>
					</div>
				</div>
				<div className="Section ingrid">
					<div className="container">
							<div className="row">
								<div className="col-md-6">
									 <div className="content-blk">
										 <h2 className="color-black">EXPRESSION OF LIVING HUMANISTIC BEAUTY</h2>
										 <p className="color-black font-18">Building has life, though it is clotting, but there is humanistic thought on it. </p>
									 </div>
								</div>
								<div className="col-md-6">
									 <div className="img-blk">
										 <img src="img/ingrid1.jpg" className="img-fluid"/>
									 </div>
								</div>
							</div>
					</div>	
				</div>
				<div className="row-img">

				</div>
				<div className="Section ingrid">
					<div className="container">
							<div className="row align-items-end">
							<div className="col-md-6">
									 <div className="img-blk">
										 <img src="img/ingrid2.jpg" className="img-fluid"/>
									 </div>
								</div>
								<div className="col-md-6">
									 <div className="content-blk">
										 <h2 className="color-black">EXPRESSION OF LIVING HUMANISTIC BEAUTY</h2>
										 <p className="color-black font-18">Building has life, though it is clotting, but there is humanistic thought on it. </p>
									 </div>
								</div>
								
							</div>
					</div>	
				</div>
			</div>
		</>
	);
}

export default Still;