import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function Contact(){
    return (
        <>
            <div className="bradcrumb light-bg">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="section contact-us">
                <div className="container ">
                    <div className="row ">
                        <div className="col-xl-6 col-md-6">
                            <div className="address-book">
                                <div className="single-news-card mt-0">
                                    <div className="contents row">
                                        <div className="col-2">
                                            <img height="32" width="32" className="mt-1" src="img/whatsapp-icon.svg"/>
                                        </div>
                                        <div className="col-10">
                                            <div className="contact-info-heading">
                                                Whatsaap Us
                                            </div>
                                            <div className="contact-info-body">
                                                Mon - Fri from 8am to 5pm.
                                            </div>
                                            <div>
                                                <a className="contact-info-details" href="https://wa.me/919999999999?text=Hi">+91 9999999999</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contents row">
                                        <div className="col-2">
                                            <img height="32" width="32" className="mt-1" src="img/message.svg"/>
                                        </div>
                                        <div className="col-10">
                                            <div className="contact-info-heading">
                                                Mail Us
                                            </div>
                                            <div className="contact-info-body">
                                                Our friendly team is here to help you.
                                            </div>
                                            <div>
                                                <a className="contact-info-details" href="mailto:info@bayaart.in">info@bayaart.in</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="contents row mb-5">
                                        <div className="col-2">
                                            <img height="32" width="32" className="mt-1" src="img/location.svg"/>
                                        </div>
                                        <div className="col-10">
                                            <div className="contact-info-heading">
                                                Locate Us
                                            </div>
                                            <div className="contact-info-body">
                                                Come say hello at our office HQ.
                                            </div>
                                            <div>
                                                <a className="contact-info-details" href="https://maps.app.goo.gl/BN9o18VjQYnCbDE2A">H-221,4th floor,office number-407,sector-63,Noida,201301</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6">
                            <div className="cta-form">
                                <div className="section-title text-center p-0">
                                    <h2 className="color-primary font-30 mb-3">Love To Hear From You, Get In Touch 👋</h2>
                                </div>
                                <div className="contact-form ">
                                    <form id="base_form" method="post" data-gtm-form-interact-id="0">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_name" className="form-label">Name</label>
                                                        <input type="text" name="base_name" id="base_name" placeholder="Enter here" className="form-control" value=""/>
                                                            
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_email" className="form-label">Email</label>
                                                        <input type="email" name="base_email" id="base_email" placeholder="Enter here" className="form-control" value=""/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_mobile_number" className="form-label">Phone number</label>
                                                        <div className="input-group">
                                                            <span className="input-group-text" id="basic-addon1">+91</span>
                                                            <input type="text" id="base_mobile_number" name="base_mobile_number" className="form-control" placeholder="Enter here"/>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_subject" className="form-label">Subject</label>
                                                        <input type="text" name="base_subject" id="base_subject" placeholder="Enter here" className="form-control" value=""/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group mb-2 book-input">
                                                        <label for="base_message" className="form-label">Write your message</label>
                                                        <textarea name="base_message" id="base_message" cols="3" rows="3" value="" placeholder="Enter here" className="form-control" data-gtm-form-interact-field-id="0">                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-sm-12 mt-3 mb-4 ">
                                                    <button type="submit" id="base_submit" className="btn btn-primary bt-blue">
                                                        Send Message
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  
    );
}
export default Contact;